import React from "react";

import { Layout, PortfolioNav, PortfolioHeader } from "../../../components";

import locales from "../../../constants";

const Prodinity = ({ pageContext: { slug } }) => {
  const nav = {
    initialSlideIndex: 1,
  };
  const lang = "en";
  return (
    <Layout
      seo={{
        title: "Prodinity",
        href: slug,
        lang: "en",
        ogImage: require("../../../assets/img/portfolio/prodinity_og.jpg"),
      }}
      header={{
        background:
          "linear-gradient( 45deg, #E36393, #B1446D, #AF446C, #CD4D7D, #E16090 )",
        icons: "#e42978",
        navClass: "prodinity__nav",
        ogImage: require("../../../assets/img/portfolio/piotrus_pan_top_back.jpg"),
      }}
      langSwitch={{
        langKey: "pl",
        langSlug: "/projekty/prodinity/",
      }}
    >
      <PortfolioHeader name="prodinity" height="260" />
      <div className="prodinity">
        <section
          className="container-fluid portfolio-intro prodinity-section-2"
          id="info"
        >
          <div className="row">
            <div className="col-md-6">
              <div className="inner">
                <h1>Prodinity</h1>
                <ul>
                  <li>Strategy</li>
                  <li>Rebranding (naming, claim)</li>
                  <li>Logo</li>
                  <li>Key visual</li>
                  <li>Web development</li>
                  <li>E-mail signature</li>
                  <li>Marketing materials</li>
                </ul>
              </div>
            </div>
            <div className="col-md-6">
              <div className="inner inner--divider">
                <p>
                  In cooperation with our client, Prodinity (formerly BIOLAP Limited), we have carried out a complete rebranding process of a global brand, which aggregates huge amounts of data and adapts management systems to the needs of its clients (especially for insurance brokers). Prodinity has carried out multiple projects for big brands, such as AON, HSBC or Electrolux.
                </p>
              </div>
            </div>
          </div>
        </section>
        <section className="prodinity-section-3">
          <div className="inner">
            <h2>Strategy</h2>
            <p>
              After 2 months of extensive work, we have developed a complete brand communication strategy for the global market Prodinity is targeting. We have established target groups, the direction that should be taken into account when designing a new visual identity of the brand, and all key factors necessary for the brand to achieve its marketing and sales goals.
            </p>
          </div>
          <img
            src={require("../../../assets/img/portfolio/prodinity_main_1.png")}
            alt=""
          />
        </section>
        <section className="prodinity-section-4">
          <div className="inner">
            <h2>Logo</h2>
            <p>
              The new logo was designed on the basis of the new brand's communication strategy. The sigil presents the formed blocks of structured data, which directly relates to the main services offered by the company.
            </p>
          </div>
          <div className="logo-presentation">
            <div className="inner">
              <img
                src={require("../../../assets/img/portfolio/prodinity_vertical_logo.svg")}
                alt=""
              />
              <img
                src={require("../../../assets/img/portfolio/prodinity_horizontal_logo.svg")}
                alt=""
              />
            </div>
          </div>
          <img
            className="books"
            src={require("../../../assets/img/portfolio/prodinity_main_3.png")}
            alt=""
          />
        </section>
        <section className="prodinity-section-5">
          <div className="inner">
            <h2>Web development</h2>
            <p>
              We have designed and coded a modern and fast website, based on ReactJS technology. There is a dedicated contact form on the website that has many variants and variables depending on the needs of potential customers in relation to carrying out projects with Prodinity.
            </p>
          </div>
          <div className="scroll_wrapper scroll_wrapper--macbook">
            <div className="img_wrapper">
              <img
                src={require("../../../assets/img/portfolio/prodinity_full.jpg")}
                className="img-fluid"
                alt=""
                draggable="false"
              />
            </div>
          </div>
        </section>
        <section className="prodinity-section-6">
          <div className="top">
            <img
              src={require("../../../assets/img/portfolio/prodinity_main_4.png")}
              alt=""
            />
            <img
              src={require("../../../assets/img/portfolio/prodinity_main_5.png")}
              alt=""
            />
            <img
              src={require("../../../assets/img/portfolio/prodinity_main_6.png")}
              alt=""
            />
          </div>
          <div className="bottom">
            <img
              src={require("../../../assets/img/portfolio/prodinity_main_7.png")}
              alt=""
            />
            <img
              src={require("../../../assets/img/portfolio/prodinity_main_8.png")}
              alt=""
            />
          </div>
        </section>
        <section className="prodinity-section-7">
          <div className="inner">
            <h2>E-mail signature</h2>
            <p>
              We have designed and coded e-mail signatures, consistent with the brand's new visual identity. The signatures have been coded in two variants to work properly for both e-mail programs supporting HTML code and those not offering such support.
            </p>
          </div>
          <div className="tablet">
            <img
              src={require("../../../assets/img/portfolio/prodinity_tablet.png")}
              alt=""
            />
          </div>
        </section>
        <section className="prodinity-section-8">
          <div className="inner">
            <h2>Marketing materials</h2>
            <p>
              As part of the project, we have prepared a number of advertising materials e.g. mugs, pens, briefcases and T-shirts.
            </p>
          </div>
          <img
            src={require("../../../assets/img/portfolio/prodinity_main_9.png")}
            alt=""
          />
        </section>
      </div>
      <PortfolioNav data={nav} locales={locales[lang]} />
    </Layout>
  );
};

export default Prodinity;
